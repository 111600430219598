<template>
  <div id="nav">
    <router-view />
  </div>
</template>

<style lang="less">
#app {
  font-family: "Regular";
  background: #f3f3f3;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

ul,
li {
  list-style-type: none;
}
</style>

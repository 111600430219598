import {
    createRouter,
    createWebHistory
} from 'vue-router'

const routes = [{
        path: '/saas/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: {
            title: '登入'
        }
    },
    {
        path: '/saas/wxlogin',
        name: 'wxlogin',
        component: () => import('@/views/login/wxlogin'),
        meta: {
            title: '微信登入'
        }
    },
    {
        path: '/enter',
        name: 'enter',
        component: () => import('../views/enter'),
        children: [{
                path: '404',
                name: '404',
                component: () => import('../views/mainTenance'),
                meta: {
                    title: '系统维护'
                }
            },
            {
                // 银行卡
                path: '/bankCard',
                name: 'bankCard',
                component: () => import('../views/bankCard'),
                meta: {
                    title: '银行卡'
                },
            },
            {
                path: '/alarmStatus',
                name: 'alarmStatus',
                component: () => import('../views/alarmStatus'),
                meta: {
                    title: '告警'
                }
            },
            {
                path: 'editPassword',
                name: 'editPassword',
                component: () => import('../views/editPassword'),
                meta: {
                    title: '商铺管理系统-修改密码'
                }
            },
            {
                // 新首页
                path: 'h5Home',
                name: 'h5Home',
                component: () => import('../views/newHome'),
                meta: {
                    title: '首页'
                },
            },
            {
                path: '/h5Income',
                name: 'h5Income',
                component: () => import('../views/newIncome'),
                meta: {
                    title: '我的收益'
                },
            },

            {
                // 列表模型页面
                path: '/StoreManagement',
                children: [{
                        path: '',
                        name: 'StoreManagement',
                        component: () => import('../views/StoreManagement'),
                        meta: {
                            title: '门店管理'
                        }
                    },
                    {
                        path: 'StoreDetail',
                        name: 'StoreDetail',
                        component: () => import('../views/StoreDetail'),
                        meta: {
                            title: '门店详情'
                        }
                    },
                    {
                        path: 'subdetail',
                        name: 'subdetail',
                        component: () => import('../views/StoreDetail/subdetail'),
                        meta: {
                            title: '公海门店详情'
                        }
                    },
                    {
                        path: 'ApplyStore',
                        name: 'ApplyStore',
                        component: () => import('../views/ApplyStore'),
                        meta: {
                            title: '门店申请'
                        }
                    },
                    {
                        path: 'ApplyEquipment',
                        name: 'ApplyEquipment',
                        component: () => import('../views/ApplyEquipment'),
                        meta: {
                            title: '设备申请'
                        }
                    },
                    {
                        path: 'done',
                        name: 'done',
                        component: () => import('../views/DonePage'),
                        meta: {
                            title: '提交成功'
                        }
                    }
                ],
                meta: {
                    title: '门店管理'
                }
            },
            {
                path: 'storeOverview',
                name: 'storeOverview',
                component: () => import('../views/storeOverview'),
                meta: {
                    title: '门店概览'
                }
            },
        ],
    },
    {
        path: '/count',
        name: 'count',
        component: () => import('../views/enter'),
        children: [{
            path: ':pt',
            name: 'count1',
            component: () => import('../views/count'),
            meta: {
                title: 'MetaDeck'
            }
        }],
    },
    {
        path: '/tabs',
        name: 'tabs',
        component: () => import('../views/enter'),
        children: [{
            path: ':pt',
            name: 'tabs1',
            component: () => import('../views/tabs'),
            meta: {
                title: 'MetaDeck'
            }
        }],
    },
    {
        path: '/event',
        name: 'evententer',
        component: () => import('../views/enter'),
        children: [{
            path: ':pt',
            name: 'event',
            component: () => import('../views/event'),
            meta: {
                title: 'MetaDeck'
            }
        }],
    },
    {
        path: '/doubleEleven',
        name: 'doubleEleven',
        component: () => import('@/views/doubleEleven'),
        meta: {
            title: '双十一活动'
        }
    },
    {
        path: '/DoubleElevenOrder',
        name: 'DoubleElevenOrder',
        component: () => import('../views/DoubleElevenOrder'),
        meta: {
            title: '订单查询'
        }
    },

    {
        path: '/payPage',
        name: 'payPage',
        component: () => import('../views/payPage'),
        meta: {
            title: '支付'
        }
    },

    {
        path: '/payDonePage',
        name: 'payDonePage',
        component: () => import('../views/payDonePage'),
        meta: {
            title: '支付成功'
        }
    },
    {
        path: '/payDonePageTwo',
        name: 'payDonePageTwo',
        component: () => import('../views/payDonePageTwo'),
        meta: {
            title: '支付成功'
        }
    },
    {
        path: '/activityPoster',
        name: 'activityPoster',
        component: () => import('../views/activityPoster'),
        meta: {
            title: '活动海报'
        }
    },
    {
        path: '/promotionList',
        name: 'promotionList',
        component: () => import('../views/promotionList'),
        meta: {
            title: '活动列表'
        }
    },

    {
        path: '/subscriber',
        name: 'subscriber',
        component: () => import('../views/subscriber'),
        meta: {
            title: '会员充值'
        }
    },
    {
        path: '/shop',
        name: 'shop',
        component: () => import('../views/shop'),
        meta: {
            title: '且玩商城'
        }
    },
    {
        path: '/shopdetail',
        name: 'shopdetail',
        component: () => import('../views/shopdetail'),
        meta: {
            title: '商品详情'
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title
    next()
})


export default router
import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios'
import VueAMap, {
    initAMapApiLoader
} from '@vuemap/vue-amap';
// import './mock'
import 'vant/lib/index.css';
import '@/assets/font/font.css'
import '@/assets/css/restyle.css'
import '@/assets/css/iconfont.css'
import '@vuemap/vue-amap/dist/style.css'
import 'vant/es/toast/style';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
initAMapApiLoader({
    key: '3ff62cc2eeeaa86368f1d1b566f1fd17',
    securityJsCode: 'f3ed7b24853dba01e38f40df89e579de', // 新版key需要配合安全密钥使用
    //Loca:{
    //  version: '2.0.0'
    //} // 如果需要使用loca组件库，需要加载Loca
})
import {
    Swipe,
    SwipeItem,
    Uploader,
    List,
    Calendar,
    Form,
    Field,
    CellGroup,
    RadioGroup,
    Radio,
    Rate,
    Slider,
    Stepper,
    Picker,
    Popup,
    DatetimePicker,
    Area,
    NavBar,
    Image as VanImage,
    Cascader,
    TreeSelect,
    Search,
    Icon,
    Toast,
    Checkbox,
    CheckboxGroup,
    Switch,
    Lazyload,
    Button,
    CountDown
} from "vant";

const app = createApp(App);

app.use(router)
    .use(VueAMap)
    .use(Swipe)
    .use(SwipeItem)
    .use(Uploader)
    .use(List)
    .use(Calendar)
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(RadioGroup)
    .use(Radio)
    .use(Rate)
    .use(Slider)
    .use(Stepper)
    .use(Picker)
    .use(Popup)
    .use(DatetimePicker)
    .use(Area)
    .use(NavBar)
    .use(VanImage)
    .use(Cascader)
    .use(TreeSelect)
    .use(Search)
    .use(Icon)
    .use(Toast)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Switch)
    .use(Lazyload)
    .use(VueViewer, {
        defaultOptions: {
            zIndex: 9999,
            toolbar: false,
            transition: false,
            title: false,
            navbar: false,
            scalable: false,
            rotatable: false,
            tooltip: false,
            inline: false,
        }

    })
    .use(Button)
    .use(CountDown)
    .mount('#app');


app.config.globalProperties.$axios = axios;

const $ = require('jquery');
window.$ = $;